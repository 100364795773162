export function initDetails() {
  const details = document.querySelectorAll(".js-details");

  details.forEach((element) => {
    element.addEventListener("toggle", function () {
      const summary = element.querySelector("summary span") as HTMLElement;
      const textClosed = summary?.dataset.textClosed;
      const textOpen = summary?.dataset.textOpen;

      if (!summary || !textClosed || !textOpen) {
        return;
      }

      if ((element as HTMLDetailsElement).open) {
        summary.textContent = textOpen;
      } else {
        summary.textContent = textClosed;
      }
    });
  });
}
