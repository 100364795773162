export default function initFactsheetNavLink() {
  const section = document.querySelectorAll(".js-factsheet-section");

  window.addEventListener("scroll", () => {
    section.forEach((element) => {
      const link = document.querySelector(`a[href='#${element.id}']`);

      if (
        element.getBoundingClientRect().top <= 0.5 &&
        element.getBoundingClientRect().bottom >= 0.5
      ) {
        link?.classList.add("active");
      } else {
        link?.classList.remove("active");
      }
    });
  });
}
