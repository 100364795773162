import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default function initAnimations() {
  if (reduceMotionActive()) {
    return;
  }

  const singleItems = document.querySelectorAll(".js-animate-appear");
  makeSingleItemsAppear(singleItems);

  const groups = document.querySelectorAll(".js-animate-group");
  makeGroupsAppear(groups);

  const horizontalGroups = document.querySelectorAll(
    ".js-animate-group-horizontal"
  );
  makeGroupsAppear(horizontalGroups, 0.8, 0.2, true);
}

export function reduceMotionActive() {
  return (
    window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
    window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true
  );
}

function makeSingleItemsAppear(elements) {
  elements.forEach((element) => {
    gsap.from(element, {
      scrollTrigger: {
        trigger: element,
        start: "top bottom-=15%",
      },
      duration: 0.5,
      opacity: 0,
      ease: "power1.out",
      y: 30,
    });
  });
}

function makeGroupsAppear(
  groups,
  duration = 0.5,
  stagger = 0.15,
  horizontal = false
) {
  const direction = horizontal ? { x: 30 } : { y: 30 };
  groups.forEach((group) => {
    const items = group.querySelectorAll(".js-animate-group-item");
    gsap.from(items, {
      scrollTrigger: {
        trigger: group,
        start: "top bottom-=15%",
      },
      duration: duration,
      opacity: 0,
      ease: "power1.out",
      stagger: stagger,
      ...direction,
    });
  });
}
