export default function initMenu() {
  const header = document.querySelector("#js-header");
  const menu = document.querySelector(
    "#js-menu-sandwich"
  ) as HTMLElement | null;
  const menuText = menu?.querySelector("span");

  if (!header || !menu || !menuText) {
    return;
  }

  const openText = menuText.dataset.open ?? "Menu";
  const closeText = menuText.dataset.close ?? "Close";

  menu.addEventListener("click", () => {
    header.classList.toggle("menu-open");
    menuText.textContent = header.classList.contains("menu-open")
      ? closeText
      : openText;
  });
}
