import classNames from "classnames";
import React, { useState, useRef, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { z } from "zod";
import { BrowserRouter as Router, useSearchParams } from "react-router-dom";

const DEFAULT_LANGUAGE_VALUE = "Any Language";
const DEFAULT_YEAR_VALUE = "Year";

const options = z.array(
  z.object({
    title: z.string(),
    slug: z.string(),
  })
);

type Options = z.infer<typeof options>;

const popularSearches = z.array(
  z.object({
    title: z.string().nullable(),
    text: z.string().nullable(),
    "author-institution": z.string().nullable(),
    "region-country": z.string().nullable(),
    language: z.string().nullable(),
    "year-from": z.string().nullable(),
    "year-to": z.string().nullable(),
  })
);

type PopularSearches = z.infer<typeof popularSearches>;

const categories = z.array(z.string());

const recentlyAdded = z.array(
  z.object({
    title: z.string(),
    image: z.string().nullable(),
    url: z.string(),
    authors: categories,
    years: categories,
  })
);

type RecentlyAdded = z.infer<typeof recentlyAdded>;

const props = z.object({
  languages: options,
  years: options,
  popularSearches,
  recentlyAdded,
});

type Props = z.infer<typeof props>;

const documentsSchema = z.array(
  z.object({
    title: z.string(),
    url: z.string(),
    authors: categories,
    institutions: categories,
    regions: categories,
    countries: categories,
    languages: categories,
    years: categories,
  })
);

type Documents = z.infer<typeof documentsSchema>;

type State =
  | { type: "loading" }
  | { type: "error"; message: string }
  | { type: "documents"; documents: Documents }
  | null;

export default function initSearch() {
  const rootNode = document.getElementById("search");

  if (!rootNode) {
    return;
  }

  const data = props.parse(JSON.parse(rootNode?.dataset.search ?? ""));

  const root = createRoot(rootNode);
  root.render(
    <Router>
      <Search {...data} />
    </Router>
  );
}

function Search({ languages, years, popularSearches, recentlyAdded }: Props) {
  const [state, setState] = useState<State>(null);
  const [searchParams, _] = useSearchParams();

  const textParam = searchParams.get("text");
  const authorOrInstitutionParam = searchParams.get("author-institution");
  const regionOrCountryParam = searchParams.get("region-country");
  const languageParam = searchParams.get("language");
  const yearFromParam = searchParams.get("year-from");
  const yearToParam = searchParams.get("year-to");

  const filterParams = [
    authorOrInstitutionParam,
    regionOrCountryParam,
    languageParam,
    yearFromParam,
    yearToParam,
  ].filter((param): param is string => param !== null);

  const isFiltering = filterParams.length > 0;
  const isSearching = textParam || isFiltering;

  useEffect(() => {
    if (isSearching) {
      const getInitialDocuments = async () => {
        const params = new URLSearchParams(window.location.search);
        await getDocuments(params.toString(), setState);
      };

      getInitialDocuments();
    }
  }, []);

  async function handleSubmit() {
    const params = new URLSearchParams(window.location.search);
    await getDocuments(params.toString(), setState);
  }

  return (
    <>
      <form
        className="mb-12 sm:mb-16"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="mb-4 sm:mb-6 sm:flex sm:gap-6">
          <div className="mb-3 sm:mb-0 sm:w-[70%] lg:w-[640px]">
            <InputSearch
              name="text"
              value={textParam}
              placeholder="Search documents..."
              isLarge={true}
            />
          </div>

          <button
            type="submit"
            className="h-[48px] sm:h-auto w-full sm:w-auto group flex items-center justify-center bg-green hover:bg-black active:bg-plum py-4 px-6 text-2xs sm:text-xs text-plum hover:text-white tracking-tight leading-[1.5] font-bold transition-colors cursor-pointer rounded-lg"
          >
            Search
            <SearchIcon />
          </button>
        </div>

        <Filter
          isActive={isFiltering}
          params={filterParams}
          onSubmit={() => handleSubmit()}
        >
          <div className="open hidden [&.open]:block">
            <div className="mb-6 sm:flex sm:gap-6">
              <div className="mb-4 sm:mb-0 sm:w-[312px]">
                <InputSearch
                  name="author-institution"
                  value={authorOrInstitutionParam}
                  placeholder="Author or institution"
                  onChange={() => handleSubmit()}
                />
              </div>
              <div className="mb-4 sm:mb-0 sm:w-[312px]">
                <InputSearch
                  name="region-country"
                  value={regionOrCountryParam}
                  placeholder="Region or country"
                  onChange={() => handleSubmit()}
                />
              </div>
              {languages.length > 0 && (
                <div className="mb-4 sm:mb-0 sm:w-[312px]">
                  <Select
                    name="language"
                    options={languages}
                    value={languageParam}
                    placeholder={DEFAULT_LANGUAGE_VALUE}
                    onChange={() => handleSubmit()}
                  />
                </div>
              )}
            </div>
            {years.length > 0 && (
              <div className="mb-1 sm:flex sm:gap-6 sm:items-center">
                <label className="block mb-3 sm:mb-0 text-2xs sm:text-xs leading-[1.5] tracking-tight">
                  Publication Date
                </label>
                <div className="flex items-center gap-6 mb-6 sm:mb-0">
                  <div className="w-[146px]">
                    <Select
                      name="year-from"
                      options={years}
                      value={yearFromParam}
                      placeholder={DEFAULT_YEAR_VALUE}
                      onChange={() => handleSubmit()}
                    />
                  </div>
                  <span className="text-xs leading-[1.5] tracking-tight">
                    to
                  </span>
                  <div className="w-[146px]">
                    <Select
                      name="year-to"
                      options={years}
                      value={yearToParam}
                      placeholder={DEFAULT_YEAR_VALUE}
                      onChange={() => handleSubmit()}
                    />
                  </div>
                </div>
                {isFiltering && <ClearButton onClick={() => handleSubmit()} />}
              </div>
            )}
          </div>
        </Filter>
      </form>
      <Page
        state={state}
        popularSearches={popularSearches}
        recentlyAdded={recentlyAdded}
      />
    </>
  );
}

const getDocuments = async (
  queryString: string,
  setState: React.Dispatch<React.SetStateAction<State>>
) => {
  try {
    setState({
      type: "loading",
    });

    const response = await fetch(`/library/documents?${queryString}`);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const responseData = await response.json();
    const documents = documentsSchema.parse(responseData);

    setState({
      type: "documents",
      documents,
    });
  } catch (err) {
    setState({
      type: "error",
      message: err,
    });
  }
};

function Page({
  state,
  popularSearches,
  recentlyAdded,
}: {
  state: State;
  popularSearches: PopularSearches;
  recentlyAdded: RecentlyAdded;
}) {
  if (!state) {
    return (
      <>
        {popularSearches.length > 0 && (
          <div className="mb-12 sm:mb-20">
            <HeadingLarge>Popular Searches</HeadingLarge>
            {popularSearches.map((popularSearch, index) => {
              const params = new URLSearchParams();

              Object.keys(popularSearch).forEach((key) => {
                if (key !== "title" && popularSearch[key]) {
                  params.append(key, popularSearch[key]);
                }
              });

              return (
                <a
                  key={popularSearch.title}
                  className="text-xs leading-[1.4] sm:text-md sm:leading-[1.5] tracking-tight"
                  href={`${window.location.origin}${
                    window.location.pathname
                  }?${params.toString()}`}
                >
                  “{popularSearch.title}”
                  {index < popularSearches.length - 1 && ", "}
                </a>
              );
            })}
          </div>
        )}
        {recentlyAdded.length > 0 && (
          <div className="mb-12 lg:mb-20">
            <HeadingLarge>Recently Added</HeadingLarge>
            <div className="lg:grid lg:grid-cols-12 lg:gap-x-9">
              {recentlyAdded.map((document) => (
                <div
                  key={document.url}
                  className="lg:col-span-4 flex gap-7 mb-6 last:mb-0 lg:mb-0"
                >
                  {document.image ? (
                    <a href={document.url}>
                      <img
                        className="max-w-[114px]"
                        src={document.image}
                        alt={document.title}
                        loading="lazy"
                      />
                    </a>
                  ) : null}
                  <div>
                    <div className="mb-2 lg:mb-4">
                      <a
                        key={document.url}
                        className="inline font-bridge-head-text text-2xs lg:text-sm leading-[1.3] hover:border-solid hover:border-b-[1.5px] hover:border-purple"
                        href={document.url}
                      >
                        {document.title}
                      </a>
                    </div>
                    <span className="block text-2xs text-[#3F3F3F] leading-[1.5] tracking-tight">
                      {[document.authors[0], document.years[0]]
                        .filter((value): value is string => value != null)
                        .join(", ")}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </>
    );
  }

  switch (state.type) {
    case "loading":
      return (
        <HeadingSmall>
          Searching...
          <img
            className="h-[24px] w-[24px] ml-6"
            src="/assets/images/loading.gif"
          />
        </HeadingSmall>
      );

    case "error":
      return <h3 className="font-bold">{JSON.stringify(state?.message)}</h3>;

    case "documents":
      return (
        <div>
          <HeadingSmall>
            <span>
              Search Results{" "}
              <span className="text-[#757575]">({state.documents.length})</span>
            </span>
          </HeadingSmall>
          {state.documents.length > 0 ? (
            <div>
              <table className="border-collapse w-full">
                <thead className="sm:border-solid sm:border-b-[1px] sm:border-[#ddd]">
                  <tr className="font-akkurat-mono font-normal text-3xs text-[#3F3F3F] tracking-wider leading-[1.5] uppercase">
                    <th className="hidden sm:table-cell font-normal text-left py-4">
                      Document Title
                    </th>
                    <th className="hidden sm:table-cell w-[30%] font-normal text-left py-4">
                      Author or Institution
                    </th>
                    <th className="hidden sm:table-cell w-[20%] font-normal text-left py-4">
                      Region or Country
                    </th>
                    <th className="hidden sm:table-cell font-normal text-left py-4">
                      Language
                    </th>
                    <th className="hidden sm:table-cell font-normal text-right py-4">
                      Year
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {state.documents.map((document) => (
                    <tr
                      className="border-solid border-b-[1px] border-[#ddd] block sm:table-row py-4"
                      key={document.url}
                    >
                      <td className="font-bridge-head-text text-md sm:text-xs leading-[1.3] tracking-tight sm:py-4 sm:pr-4 mb-4 sm:mb-0 block sm:table-cell">
                        <a
                          className="hover:border-solid hover:border-b-[1.5px] hover:border-purple"
                          href={document.url}
                        >
                          {document.title}
                        </a>
                      </td>
                      <td className="text-3xs sm:text-2xs sm:py-4 sm:pr-4 block sm:table-cell">
                        {[
                          ...new Set([
                            ...document.authors,
                            ...document.institutions,
                          ]),
                        ].join(", ")}
                      </td>
                      <td className="text-3xs sm:text-2xs sm:py-4 sm:pr-4 block sm:table-cell">
                        {[
                          ...new Set([
                            ...document.regions,
                            ...document.countries,
                          ]),
                        ].join(", ")}
                      </td>
                      <td className="text-3xs sm:text-2xs sm:py-4 sm:pr-4 block sm:table-cell">
                        {document.languages.join(", ")}
                      </td>
                      <td className="text-3xs sm:text-2xs sm:py-4 sm:text-right block sm:table-cell">
                        {document.years.join(", ")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <>
              <h4 className="mb-4 sm:mb-6 font-bridge-head-ext font-bold text-lg sm:text-xl leading-[1.2] sm:leading-[1.3]">
                Oops! Looks like we don’t have any documents matching your
                search criteria.
              </h4>
              <p className="text-xs sm:text-md leading-[1.4] sm:leading-[1.5] tracking-tight">
                Try adjusting your search.
              </p>
            </>
          )}
        </div>
      );
  }
}

function Filter({
  isActive,
  params,
  children,
  onSubmit,
}: {
  isActive: boolean;
  params: string[];
  children: React.ReactNode;
  onSubmit: () => void;
}) {
  const [active, setActive] = useState(isActive);

  return (
    <>
      <div className="flex items-center mb-4 sm:mb-6">
        <button
          type="button"
          className={classNames(
            "filter flex items-center text-plum leading-[1.5] tracking-tight",
            { active: active }
          )}
          onClick={() => setActive(!active)}
        >
          <span className="text-2xs sm:text-xs font-bold">
            {active ? `Hide filters` : "Show filters"}
          </span>
          {params.length > 0 ? (
            <span className="bg-purple font-normal text-4xs px-2 rounded-[100px] ml-2">
              {params.length}
            </span>
          ) : null}
          <DownArrowIcon />
        </button>
        {!active && (
          <div className="flex gap-4 ml-6">
            {params.map((param) => (
              <Pill key={param} param={param} onClick={() => onSubmit()} />
            ))}
          </div>
        )}
      </div>
      <div className={classNames({ hidden: !active, block: active })}>
        {children}
      </div>
    </>
  );
}

function InputSearch({
  name,
  value,
  placeholder,
  isLarge = false,
  onChange,
}: {
  name: string;
  value: string | null;
  placeholder: string;
  isLarge?: boolean;
  onChange?: () => void;
}) {
  const [_, setSearchParams] = useSearchParams();
  const timerRef = useRef<number | null>(null);

  return (
    <input
      className={classNames(
        "w-full rounded-lg tracking-tight focus:outline-none border-solid border-[2px] border-white hover:border-purple focus:border-plum transition-colors",
        {
          "p-4 text-xs h-[56px] sm:h-[62px]": isLarge,
          "p-3 text-2xs leading-[1.5] h-[56px]": !isLarge,
        }
      )}
      type="search"
      name={name}
      value={value ?? ""}
      placeholder={placeholder}
      autoComplete="off"
      onChange={(e) => {
        setSearchParams(
          (prev) => {
            if (e.target.value) {
              prev.set(name, e.target.value);
            } else {
              prev.delete(name);
            }

            return prev;
          },
          { replace: true }
        );

        if (onChange) {
          clearTimeout(timerRef.current ?? undefined);
          timerRef.current = setTimeout(() => {
            onChange();
          }, 1000);
        }
      }}
    />
  );
}

function Select({
  name,
  value,
  options,
  placeholder,
  onChange,
}: {
  name: string;
  value: string | null;
  options: Options;
  placeholder: string;
  onChange?: () => void;
}) {
  const [_, setSearchParams] = useSearchParams();

  return (
    <div className="relative after:content-[''] after:absolute after:right-0 after:top-[50%] after:translate-y-[-50%] after:mr-3 after:h-[24px] after:w-[24px] after:bg-down-arrow">
      <select
        className="appearance-none w-full p-3 text-2xs leading-[1.5] rounded-lg tracking-tight focus:outline-none border-solid border-[2px] border-white hover:border-purple focus:border-plum transition-colors"
        name={name}
        value={value ?? ""}
        onChange={(e) => {
          setSearchParams(
            (prev) => {
              if (e.target.value !== placeholder) {
                prev.set(name, e.target.value);
              } else {
                prev.delete(name);
              }

              return prev;
            },
            { replace: true }
          );

          if (onChange) {
            onChange();
          }
        }}
      >
        <option defaultValue="">{placeholder}</option>
        {options.map((option) => (
          <option key={option.slug} defaultValue={option.slug}>
            {option.title}
          </option>
        ))}
      </select>
    </div>
  );
}

function Pill({ param, onClick }: { param: string; onClick: () => void }) {
  const [_, setSearchParams] = useSearchParams();

  return (
    <button
      className="flex items-center text-contrasting-purple border-solid border-contrasting-purple border-[1px] rounded-lg text-4xs leading-[1.5] tracking-tight px-3 py-1"
      type="button"
      onClick={() => {
        setSearchParams(
          (prev) => {
            for (const [key, val] of prev.entries()) {
              if (val === param) {
                prev.delete(key);
              }
            }
            return prev;
          },
          { replace: true }
        );

        onClick();
      }}
    >
      {param}
      <CloseIconSmall />
    </button>
  );
}

function ClearButton({ onClick }: { onClick: () => void }) {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <button
      className="flex items-center font-bold text-plum text-2xs leading-[1.2] tracking-tight"
      type="button"
      onClick={() => {
        const textParam = searchParams.get("text");

        if (textParam) {
          setSearchParams({ text: textParam }, { replace: true });
        } else {
          setSearchParams(undefined, { replace: true });
        }

        onClick();
      }}
    >
      Clear all <CloseIconLarge />
    </button>
  );
}

function SearchIcon() {
  return (
    <svg
      className="ml-[10px] stroke-black group-hover:stroke-white group-active:stroke-white transition-colors"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 17L21 21M3 11C3 13.1217 3.84285 15.1566 5.34315 16.6569C6.84344 18.1571 8.87827 19 11 19C13.1217 19 15.1566 18.1571 16.6569 16.6569C18.1571 15.1566 19 13.1217 19 11C19 8.87827 18.1571 6.84344 16.6569 5.34315C15.1566 3.84285 13.1217 3 11 3C8.87827 3 6.84344 3.84285 5.34315 5.34315C3.84285 6.84344 3 8.87827 3 11Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function DownArrowIcon() {
  return (
    <svg
      className="ml-2 [.filter.active_&]:rotate-180"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 9L12 15L18 9"
        stroke="#3F0853"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function CloseIconLarge() {
  return (
    <svg
      className="ml-2"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 5.5L19 19.5M19 5.5L5 19.5"
        stroke="#3F0853"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function CloseIconSmall() {
  return (
    <svg
      className="ml-2"
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M2.5 2.5L9.5 9.5M9.5 2.5L2.5 9.5"
        stroke="#690B96"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function HeadingLarge({ children }: { children: React.ReactNode }) {
  return (
    <h3 className="mb-8 sm:mb-10 font-bold text-sm sm:text-lg leading-[1.3] tracking-tight">
      {children}
    </h3>
  );
}

function HeadingSmall({ children }: { children: React.ReactNode }) {
  return (
    <h3 className="flex items-center mb-6 sm:mb-10 font-bold text-md sm:text-sm leading-[1.2] tracking-tight">
      {children}
    </h3>
  );
}
