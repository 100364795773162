import { initDetails } from "./details";
import initFactsheetNavLink from "./factsheet-nav-link";
import initMenu from "./menu";
import initSearch from "./search";
import initCharts from "./charts";
import initAnimations from "./animations";

initMenu();
initSearch();
initFactsheetNavLink();
initDetails();
initCharts();
initAnimations();
