import { createRoot } from "react-dom/client";
import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { z } from "zod";

export default function initCharts() {
  initLineChart();
  initBarChart();
}

function initLineChart() {
  const values = z.array(
    z.object({
      name: z.string(),
      Female: z.string(),
      Male: z.string(),
      "Total Population": z.coerce.number(),
    })
  );

  const rootNode = document.getElementById("line-chart");

  if (!rootNode) {
    return;
  }

  const root = createRoot(rootNode);
  const data = values.parse(JSON.parse(rootNode?.dataset.values ?? ""));

  root.render(
    <ResponsiveContainer height={307}>
      <LineChart data={data}>
        <CartesianGrid />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip separator=": " />
        <Legend />
        <Line stroke="#3F0853" dataKey="Female" />
        <Line stroke="#9760DC" dataKey="Male" />
        <Line stroke="#F57549" dataKey="Total Population" />
      </LineChart>
    </ResponsiveContainer>
  );
}

function initBarChart() {
  const values = z.array(
    z.object({
      name: z.string(),
      Female: z.coerce.string(),
      Male: z.coerce.string(),
    })
  );

  const rootNode = document.getElementById("bar-chart");

  if (!rootNode) {
    return;
  }

  const root = createRoot(rootNode);
  const data = values.parse(JSON.parse(rootNode?.dataset.values ?? ""));

  root.render(
    <ResponsiveContainer height={307}>
      <BarChart data={data}>
        <CartesianGrid />
        <XAxis dataKey="name" />
        <YAxis
          tickFormatter={(tick) => {
            return `${tick}%`;
          }}
        />
        <Tooltip
          separator=": "
          formatter={(tick) => {
            return `${tick}%`;
          }}
        />
        <Legend />
        <Bar fill="#3F0853" dataKey="Female" />
        <Bar fill="#9760DC" dataKey="Male" />
      </BarChart>
    </ResponsiveContainer>
  );
}
